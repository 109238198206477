import React from 'react';
import PropTypes from 'prop-types';

import {
  PTBBSocialMediaSideShare,
  PTBBSocialMediaSideShareDisplay,
} from '../PTBBSocialMediaSideShare';

// TODO: use CSS modules.
const BBSocialMediaSideShareMobile = ({
  activeProviders,
  referrers,
  onPopupToggle,
  currentLocation,
  hasMobileBorders,
}) => (
  <div className={`mobile text-center ${hasMobileBorders ? 'bordered' : ''} d-md-none`}>
    <div className="container">
      <h6>Share your concern</h6>

      <div className="d-flex justify-content-between providers">
        {activeProviders.map((provider) => (
          <a
            key={provider}
            className="provider-link d-flex"
            href={`${
              referrers[provider].baseURL
                ? encodeURI(referrers[provider].baseURL + currentLocation)
                : '#'
            }`}
            target={referrers[provider].target}
            aria-label={referrers[provider].alt}
            onClick={referrers[provider].baseURL ? null : onPopupToggle}
          >
            {referrers[provider].icon}
          </a>
        ))}
      </div>
    </div>
  </div>
);

BBSocialMediaSideShareMobile.propTypes = {
  ...PTBBSocialMediaSideShare,
  currentLocation: PropTypes.string.isRequired,
  referrers: PropTypes.shape(PTBBSocialMediaSideShareDisplay).isRequired,
};

export default BBSocialMediaSideShareMobile;
