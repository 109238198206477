import React from 'react';
import PropTypes from 'prop-types';

import {
  PTBBSocialMediaSideShare,
  PTBBSocialMediaSideShareDisplay,
} from '../PTBBSocialMediaSideShare';

// TODO: use CSS modules.
const BBSocialMediaSideShareDesktop = ({
  activeProviders,
  referrers,
  onPopupToggle,
  currentLocation,
  isDesktopSticky,
}) => (
  <div className={`desktop ${isDesktopSticky ? 'sticky' : ''} d-none d-md-block`}>
    <mark>Share</mark>

    {activeProviders.map((provider) => (
      <a
        key={provider}
        className="d-flex justify-content-center provider-link"
        href={`${
          referrers[provider].baseURL
            ? encodeURI(referrers[provider].baseURL + currentLocation)
            : '#'
        }`}
        target={referrers[provider].target}
        aria-label={referrers[provider].alt}
        onClick={referrers[provider].baseURL ? null : onPopupToggle}
      >
        {referrers[provider].icon}
      </a>
    ))}
  </div>
);

BBSocialMediaSideShareDesktop.propTypes = {
  ...PTBBSocialMediaSideShare,
  currentLocation: PropTypes.string.isRequired,
  referrers: PropTypes.shape(PTBBSocialMediaSideShareDisplay).isRequired,
};

export default BBSocialMediaSideShareDesktop;
